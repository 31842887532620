autocomplete = "";
/*
 *  add_marker
 *
 *  This function will add a marker to the selected Google Map
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	$marker (jQuery element)
 *  @param	map (Google Map object)
 *  @return	n/a
 */
function add_marker($marker, map) {
  // var
  var latlng = new google.maps.LatLng(
    $marker.attr("data-lat"),
    $marker.attr("data-lng")
  );

  // create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
  });

  // add to array
  map.markers.push(marker);
}

/*
 *  center_map
 *
 *  This function will center the map, showing all markers attached to this map
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	map (Google Map object)
 *  @return	n/a
 */

function center_map(map) {
  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  jQuery.each(map.markers, function (i, marker) {
    var latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng()
    );

    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length == 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
    map.setZoom(14);
  } else {
    // fit to bounds
    map.fitBounds(bounds);
  }
}

/*
 *  new_map
 *
 *  This function will render a Google Map onto the selected jQuery element
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	$el (jQuery element)
 *  @return	n/a
 */

function new_map($el) {
  // var
  var $markers = $el.find(".marker");

  // vars
  var args = {
    zoom: 16,
    center: new google.maps.LatLng(43.047932, -76.161265),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    zoomControl: true,
  };

  // create map
  var map = new google.maps.Map($el[0], args);

  // add a markers reference
  map.markers = [];

  // add markers
  $markers.each(function () {
    add_marker(jQuery(this), map);
  });

  // center map
  center_map(map);

  // return
  return map;
}

function set_map_styles(map) {
  var styles = new google.maps.StyledMapType(
    [
      {
        featureType: "administrative",
        elementType: "all",
        stylers: [
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#444444",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            color: "#c5a069",
          },
          {
            lightness: "14",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
          {
            color: "#ba8e58",
          },
          {
            visibility: "simplified",
          },
          {
            lightness: "0",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "all",
        stylers: [
          {
            visibility: "simplified",
          },
          {
            color: "#ba8e58",
          },
          {
            lightness: "9",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels",
        stylers: [
          {
            visibility: "simplified",
          },
          {
            color: "#a19d8c",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
          {
            saturation: "-100",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            color: "#8aaebe",
          },
          {
            visibility: "on",
          },
        ],
      },
    ],
    {
      name: "Styled Map",
    }
  );

  map.mapTypes.set("styled_map", styles);
  map.setMapTypeId("styled_map");
}

var gf_map;
var gf_marker;

jQuery(document).ready(function () {
  //
  // Load the gravity forms map
  //
  if (jQuery(".gf-map").length > 0) {
    // create map
    gf_map = new_map(jQuery(".gf-map"));

    //set_map_styles(gf_map);

    gf_marker = new google.maps.Marker({
      map: gf_map,
      anchorPoint: new google.maps.Point(0, -29),
    });
  }
});

function nysmpa_update_coordinates() {
  // Somehow set the coordinates from the input.
  // Maybe
}

/*
 * Init the start and ending point autocomplete functionality via Google Maps API
 *
 * @return n/a
 */
function initAutocomplete() {
  // Create the autocomplete object, restricting the search to geographical
  // location types.
  if (jQuery(".autocomplete-input input").length > 0) {
    var id = jQuery(".autocomplete-input input").attr("id");
    //console.log("YEAH" + id);
    autocomplete = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */
      (document.getElementById(id)),
      {
        types: ["geocode"],
      }
    );

    // Update profile coordinates
    if (
      jQuery("input[name=input_22]").length > 0 &&
      jQuery("form.update-profile").length > 0
    ) {
      nysmpa_update_coordinates(autocomplete);
    }

    autocomplete.addListener("place_changed", function () {
      gf_marker.setVisible(false);
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        gf_map.fitBounds(place.geometry.viewport);
      } else {
        gf_map.setCenter(place.geometry.location);
      }
      gf_map.setZoom(12);
      gf_marker.setPosition(place.geometry.location);
      gf_marker.setVisible(true);

      var lat = gf_marker.getPosition().lat();
      var lng = gf_marker.getPosition().lng();

      // Update hidden location value.
      jQuery("input[name=input_22]").val(lat + "," + lng);
    });
  }
}

jQuery(document).ready(function () {
  // Turn browser autocomplete off for this field
  //jQuery('#input_1_19').attr('autocomplete', 'false');
});

// Show on map pin
jQuery(document).ready(function () {
  jQuery(document).on("facetwp-loaded", function () {
    if (window.FWP_MAP !== undefined) {
      jQuery(".view-on-map").on("click", function (e) {
        var post_id = jQuery(this).attr("data-location-id");
        if (
          FWP_MAP.markerLookup[post_id] !== null &&
          FWP_MAP.markerLookup[post_id] !== undefined
        ) {
          var postid = jQuery(this).attr("data-id");
          var marker = FWP_MAP.get_post_markers(post_id);
          jQuery.each(marker, function (key, value) {
            FWP_MAP.map.setCenter({
              lat: value.position.lat(),
              lng: value.position.lng(),
            });
            FWP_MAP.is_zooming = true; // Needed if you are using the "Enable map filtering" button
            FWP_MAP.map.setZoom(15); // Set a zoom level between 1 and 20. Make sure it is higher than the marker clusterer's bottom limit.

            // google.maps.event.trigger(value, 'click'); // If you don't have spiderfied markers
            google.maps.event.trigger(value, "spider_click"); // If you have spiderfied markers. Will also work if you don't have spiderfied markers.
          });

          // If we're on mobile scroll to map top.
          if (jQuery(document).width() <= 1400) {

            var hash = '#product-finder-top';

            jQuery("html, body").animate(
              {
                scrollTop: jQuery(hash).offset().top
              },
              800,
              function () {
                // Add hash (#) to URL when done scrolling (default click behavior)
                //window.location.hash = hash;
                // Close main nav.
                
                setTimeout(function () {
                  headroom.unpin();
                }, 200);
              }
            );

          }
        }
        return false;
      });
    }
  });
});

jQuery(document).ready(function () {
  jQuery(".acf-map").each(function () {
    // create map
    map = new_map(jQuery(this));
  });
});
